import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import Graphic from '../../../assets/images/landing_hero_graphic.png';
import { ReactComponent as BankIcon } from '../../../assets/icons/bank.svg';
import { ReactComponent as PawnShopIcon } from '../../../assets/icons/pawnshop.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/icons/money.svg';
import { ReactComponent as MoneyStackIcon } from '../../../assets/icons/money-stack.svg';
import styles from './HeroGraphic.module.less';

interface HeaderGraphicLabelProps {
    iconColor: string;
    icon: React.ReactNode;
    label: React.ReactNode;
    style?: CSSProperties;
}

const HeaderGraphicLabel = ({
    icon,
    iconColor,
    label,
    style,
}: HeaderGraphicLabelProps) => {
    return (
        <div className={styles.heroGraphicLabel} style={{ ...style }}>
            <span
                className={styles.heroGraphicLabelIcon}
                style={{ backgroundColor: iconColor }}
            >
                {icon}
            </span>
            <span>{label}</span>
        </div>
    );
};

const HeroGraphic = () => {
    const [t] = useTranslation();
    return (
        <div className={styles.heroGraphic}>
            <img alt="hero" src={Graphic} />
            <HeaderGraphicLabel
                style={{ left: 0, top: 30 }}
                icon={<BankIcon />}
                iconColor="#74C11D"
                label={t('landing.hero_bank_label', {
                    defaultValue: 'Банки второго уровня',
                })}
            />
            <HeaderGraphicLabel
                style={{
                    left: 20,
                    bottom: 110,
                }}
                icon={<PawnShopIcon />}
                iconColor="#EC7559"
                label={t('landing.hero_pawnshop_label', {
                    defaultValue: 'Ломбарды',
                })}
            />
            <HeaderGraphicLabel
                style={{
                    right: 0,
                    top: 110,
                    maxWidth: '220px',
                }}
                icon={<MoneyIcon />}
                iconColor="#FCBB07"
                label={t('landing.hero_micro_finance_label', {
                    defaultValue: 'Микрофинасовые организации',
                })}
            />
            <HeaderGraphicLabel
                style={{ right: 0, bottom: 30 }}
                icon={<MoneyStackIcon />}
                iconColor="#319D72"
                label={t('landing.hero_credit_label', {
                    defaultValue: 'Кредитные товарищества',
                })}
            />
        </div>
    );
};

export { HeroGraphic };
