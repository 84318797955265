import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Typography, Row, Col } from 'antd';
import { Layout, Footer, useTitle } from '@aml/shared';
import { Header } from './Header';
import { Hero } from './Hero';
import { Info } from './Info';
import { Stats } from './Stats';
import { Product } from './Product';
import { Contact } from './Contact';

import { ReactComponent as LandingChart } from '../../assets/images/landing_chart.svg';
import { ReactComponent as LandingReports } from '../../assets/images/landing_reports.svg';
import { ReactComponent as LandingFolders } from '../../assets/images/landing_folders.svg';

import styles from './Landing.module.less';

const Landing = () => {
    const [t] = useTranslation();
    useTitle(t('landing.hero_title'));
    const breakpoint = 991;
    const isMobile = useMedia(`(max-width: ${breakpoint}px)`);
    return (
        <Layout className={styles.layout}>
            <Header breakpoint={breakpoint} />
            <Layout.Content
                className={styles.content}
                style={{ overflow: 'hidden' }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Hero isMobile={isMobile} />
                    </Col>
                </Row>

                <Layout.Container style={{ overflow: 'hidden' }}>
                    <Stats isMobile={isMobile} />

                    <Row gutter={[16, 16]}>
                        <Col id="why" className={styles.textContent} span={24}>
                            <Typography.Title level={2}>
                                {t('landing.why_us_title', {
                                    defaultValue: 'Почему мы?',
                                })}
                            </Typography.Title>
                            <Typography.Paragraph>
                                {t('landing.why_us_text', {
                                    defaultValue:
                                        'У нас есть комплексное решение под ваши бизнес-задачи',
                                })}
                            </Typography.Paragraph>
                        </Col>

                        <Info>
                            <Info.Image>
                                <LandingChart width="100%" />
                            </Info.Image>
                            <Info.Text
                                title={t('landing.info_1_title', {
                                    defaultValue:
                                        'Увеличение эффективности бизнеса',
                                })}
                                paragraphs={[
                                    t('landing.info_1_bullet_1', {
                                        defaultValue:
                                            '- Расширьте клиентскую базу с минимум усилий.',
                                    }),
                                    t('landing.info_1_bullet_2', {
                                        defaultValue:
                                            '- Не переплачивайте за весь объем обращений, а платите только за своих клиентов, которые воспользовались вашими услугами.',
                                    }),
                                    t('landing.info_1_bullet_3', {
                                        defaultValue:
                                            '- Используйте качественный портфель с минимальными затратами (актуальные, верифицированные и максимально обогащённые заявки) и принимайте решение без скоринга.',
                                    }),
                                    t('landing.info_1_bullet_4', {
                                        defaultValue:
                                            '- Сокращайте маркетинговый бюджет, с нами вы значительно сэкономите расходы на рекламу',
                                    }),
                                ]}
                            />
                        </Info>

                        <Info>
                            <Info.Text
                                title={t('landing.info_2_title', {
                                    defaultValue: 'Гибкое управление портфелем',
                                })}
                                paragraphs={[
                                    t('landing.info_2_bullet_1', {
                                        defaultValue:
                                            '- Забирайте предложения, соответствующие портрету вашего клиента.',
                                    }),
                                    t('landing.info_2_bullet_2', {
                                        defaultValue:
                                            '- Регулируйте количество клиентов, исходя из потребностей и возможностей вашего бизнеса.',
                                    }),
                                    t('landing.info_2_bullet_3', {
                                        defaultValue:
                                            '- Управляйте ликвидностью и минимизируйте риски в онлайн формате, не выходя из офиса.',
                                    }),
                                ]}
                            />
                            <Info.Image>
                                <LandingReports width="100%" />
                            </Info.Image>
                        </Info>

                        <Info>
                            <Info.Image>
                                <LandingFolders width="100%" />
                            </Info.Image>
                            <Info.Text
                                title={t('landing.info_3_title', {
                                    defaultValue:
                                        'Уникальная Digital технология',
                                })}
                                paragraphs={[
                                    t('landing.info_3_bullet_1', {
                                        defaultValue:
                                            '- Уникально и реально! Участие в Программе лояльности от финансовых институтов РК.',
                                    }),
                                    t('landing.info_3_bullet_2', {
                                        defaultValue:
                                            '- Здесь и сейчас! Для вас 24/7 сотни горячих предложений.',
                                    }),
                                    t('landing.info_3_bullet_3', {
                                        defaultValue:
                                            '- Быстро и просто! Один клик в личный кабинет, и база потенциальных клиентов у вас',
                                    }),
                                    t('landing.info_3_bullet_4', {
                                        defaultValue:
                                            '- Удобно и надежно! Стабильный рост вашего дохода.',
                                    }),
                                ]}
                            />
                        </Info>
                    </Row>
                </Layout.Container>
                <Product id="about" />
                <Layout.Container>
                    <Contact id="partner" />
                </Layout.Container>
            </Layout.Content>
            <Footer />
        </Layout>
    );
};

export { Landing };
