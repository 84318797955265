import { Trans, useTranslation } from 'react-i18next';
import { Divider, Input, Typography, Row, Col } from 'antd';
import { Button, Card, Form, MaskInput } from '@aml/shared';
import clsx from 'clsx';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as BgDots } from '../../../assets/images/landing_bg_dots.svg';
import styles from './Contact.module.less';
import { useVisibility } from '../helpers';

interface ContactProps {
    id?: string;
}

const Contact = ({ id }: ContactProps) => {
    const [elementRef, visibilityClassName] = useVisibility();
    const [t] = useTranslation();
    return (
        <Card
            id={id}
            className={clsx(styles.contactCard, visibilityClassName)}
            elevated
            bordered={false}
        >
            <Row ref={elementRef} gutter={[16, 48]}>
                <Col span={24} lg={{ span: 10 }}>
                    <Typography.Title className={styles.contactTitle} level={4}>
                        {t('landing.partner_title', {
                            defaultValue: 'Хотите стать партнером?',
                        })}
                    </Typography.Title>
                    <Form className={styles.contactForm} layout="vertical">
                        <Form.Item
                            label={t('landing.partner_name', {
                                defaultValue: 'Имя',
                            })}
                        >
                            <Input
                                placeholder={t('landing.partner_placeholder', {
                                    defaultValue: 'Как вас зовут?',
                                })}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('landing.partner_phone', {
                                defaultValue: 'Номер телефона',
                            })}
                        >
                            <MaskInput
                                mask="+7 (000) 000-0000"
                                showMask
                                alwaysShowMask
                                size="large"
                                value=""
                            />
                        </Form.Item>
                        <Form.Item>
                            <Typography.Paragraph
                                className={styles.contactAgreement}
                            >
                                <Trans
                                    i18nKey="landing.partner_agreement"
                                    defaults="Нажимая на кнопку вы даете согласие на обработку <1>персональных данных</1>"
                                    components={{
                                        1: (
                                            <span
                                                className={
                                                    styles.contactAgreementHighlight
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Typography.Paragraph>
                        </Form.Item>
                        <Button type="primary" size="large" block>
                            {t('landing.partner_action', {
                                defaultValue: 'Получить доступ',
                            })}
                        </Button>
                    </Form>
                </Col>
                <Col className={styles.contactDivider} span={2}>
                    <Divider type="vertical" />
                </Col>
                <Col span={24} lg={{ span: 10 }}>
                    <Typography.Title className={styles.contactTitle} level={4}>
                        {t('landing.partner_contacts', {
                            defaultValue: 'Наши контакты',
                        })}
                    </Typography.Title>
                    <Typography.Title
                        className={styles.contactNumber}
                        level={3}
                    >
                        +7 (771) 506 35 13
                    </Typography.Title>
                    <ul className={styles.contactList}>
                        <li>
                            <span className={styles.contactListIcon}>
                                <LocationIcon />
                            </span>
                            {t('landing.partner_address', {
                                defaultValue:
                                    'Республика Казахстан, г. Алматы, ул. Таттимбета, 390.',
                            })}
                        </li>
                        <li>
                            <span className={styles.contactListIcon}>
                                <MailIcon />
                            </span>
                            <a href="mailto:info@ufinance.kz">
                                info@ufinance.kz
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
            <BgDots className={styles.contactBgDots} />
        </Card>
    );
};

export { Contact };
