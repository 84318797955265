import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col } from 'antd';
import { Button, Card, Layout } from '@aml/shared';
import clsx from 'clsx';
import { HeroGraphic } from '../HeroGraphic';
import { useScrollTo } from '../helpers';
import styles from './Hero.module.less';

interface HeroProps {
    isMobile?: boolean;
    className?: string;
    titleClassName?: string;
    paragraphClassName?: string;
}

const Hero = forwardRef<HTMLDivElement, HeroProps>((props: HeroProps, ref) => {
    const [t] = useTranslation();
    const scrollTo = useScrollTo();
    const { isMobile, className, titleClassName, paragraphClassName } = props;
    const onClick = () => {
        scrollTo('about');
    };
    return (
        <Card className={clsx(styles.heroCard, className)} bordered={false}>
            <Layout.Container>
                <Row gutter={[16, 24]} align="middle">
                    <Col span={24} lg={{ span: 12 }}>
                        <Typography.Title
                            className={clsx(styles.heroTitle, titleClassName)}
                        >
                            {t('landing.hero_title', {
                                defaultValue:
                                    'UFin - биржа клиентов для финансовых организаций',
                            })}
                        </Typography.Title>
                        <Typography.Paragraph
                            className={clsx(
                                styles.heroParagraph,
                                paragraphClassName
                            )}
                        >
                            {t('landing.hero_text', {
                                defaultValue:
                                    'Мы предлагаем решение нового поколения,  вы достигаете самых амбициозных целей.',
                            })}
                        </Typography.Paragraph>
                        <Button
                            className={styles.heroBtn}
                            type="ghost-primary"
                            size="large"
                            block={isMobile}
                            onClick={onClick}
                        >
                            {t('landing.hero_action', {
                                defaultValue: 'Узнать подробнее',
                            })}
                        </Button>
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                        <HeroGraphic />
                    </Col>
                </Row>
            </Layout.Container>
        </Card>
    );
});

export { Hero };
