import { forwardRef } from 'react';
import { InputProps } from 'antd/lib/input';
import LibMaskInput, { IInputProps } from 'react-maskinput';
import clsx from 'clsx';

interface MaskInputProps extends IInputProps {
    size?: InputProps['size'];
}

const defaultMaskFormat = [
    {
        str: '0',
        regexp: /[0-9]/,
    },
    {
        str: '*',
        regexp: /./,
    },
    {
        str: 'a',
        regexp: /[a-zA-Z]/,
    },
    {
        str: 'A',
        regexp: /[A-Z0-9]/,
    },
];

const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>((props, ref) => {
    const { className, size, maskChar = '_', ...rest } = props;
    let sizeClass = '';
    if (size && size !== 'middle') {
        sizeClass = `ant-input-${size === 'large' ? 'lg' : 'sm'}`;
    }
    return (
        <LibMaskInput
            getReference={(el) => {
                if (ref) {
                    (ref as any).current = el;
                }
            }}
            className={clsx('ant-input', sizeClass, className)}
            maskChar={maskChar}
            maskFormat={defaultMaskFormat}
            {...rest}
        />
    );
});

export { MaskInput, defaultMaskFormat };
