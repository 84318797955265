import { Trans, useTranslation } from 'react-i18next';
import { Typography, Row, Col } from 'antd';
import { Card, Layout } from '@aml/shared';
import clsx from 'clsx';
import Laptop from '../../../assets/images/landing_laptop.png';
import styles from './Product.module.less';
import { useVisibility } from '../helpers';

interface ProductProps {
    id?: string;
}

const Product = ({ id }: ProductProps) => {
    const [elementRef, visibilityClassName] = useVisibility();
    const [t] = useTranslation();
    return (
        <Card
            id={id}
            className={clsx(styles.productCard, visibilityClassName)}
            bordered={false}
        >
            <div className={styles.productBgObject}></div>
            <Layout.Container className={styles.productFg}>
                <Row ref={elementRef} gutter={[32, 64]}>
                    <Col span={24} lg={{ span: 12 }}>
                        <Typography.Title
                            className={styles.productTitle}
                            level={2}
                        >
                            {t('landing.about_title', {
                                defaultValue: 'О продукте UFin',
                            })}
                        </Typography.Title>
                        <Typography.Title
                            className={styles.productSubtitle}
                            level={4}
                        >
                            <Trans
                                i18nKey="landing.about_subtitle"
                                defaultValue="Первый на рынке <1>маркетплейс</1> не имеющий аналогов"
                                components={{
                                    1: <span style={{ color: '#47A07E' }} />,
                                }}
                            />
                        </Typography.Title>
                        <Typography.Paragraph className={styles.productText}>
                            {t('landing.about_text_1', {
                                defaultValue:
                                    'Наша задача - предоставление инновационного решения, которое минимизирует затраты финансовых учреждений для расширения возможностей, позволит увеличить кредитный портфель и клиентскую базу, а также оставить конкурентов далеко позади.',
                            })}
                        </Typography.Paragraph>
                        <Typography.Paragraph className={styles.productText}>
                            {t('landing.about_text_2', {
                                defaultValue:
                                    'Компания UFinance, изучив текущий финансовый рынок, создала для Микрофинансовых организаций и других финансовых институтов инновационную гибкую среду, в которой организация может сформировать собственный портфель путем определения необходимых критериев в разрезе каждого запроса. ',
                            })}
                        </Typography.Paragraph>
                    </Col>
                    <Col
                        className={styles.productImage}
                        span={24}
                        lg={{ span: 12 }}
                    >
                        <img
                            className={styles.productLaptop}
                            alt="Laptop"
                            src={Laptop}
                        />
                    </Col>
                </Row>
            </Layout.Container>
        </Card>
    );
};

export { Product };
