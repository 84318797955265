import { FC } from 'react';
import { Typography, Row, Col } from 'antd';
import clsx from 'clsx';
import { useVisibility } from '../helpers';
import styles from './Info.module.less';

interface InfoProps {
    id?: string;
    children?: React.ReactNode;
}

const Info = ({ id, children }: InfoProps) => {
    const [elementRef, visibilityClassName] = useVisibility();
    return (
        <Row
            ref={elementRef}
            id={id}
            className={clsx(styles.info, visibilityClassName)}
            gutter={16}
        >
            {children}
        </Row>
    );
};

interface InfoImageProps {
    className?: string;
}

const InfoImage: FC<InfoImageProps> = ({ className, children }) => {
    return (
        <Col
            className={clsx(styles.image, className)}
            span={24}
            lg={{ span: 12 }}
        >
            {children}
        </Col>
    );
};

interface InfoTextProps {
    className?: string;
    titleClassName?: string;
    paragraphClassName?: string;
    title: React.ReactNode;
    paragraphs?: React.ReactNode[];
}

const InfoText = (props: InfoTextProps) => {
    const {
        className,
        titleClassName,
        paragraphClassName,
        title,
        paragraphs,
    } = props;
    return (
        <Col
            className={clsx(styles.text, className)}
            xs={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 0 }}
        >
            <Typography.Title className={clsx(styles.title, titleClassName)}>
                {title}
            </Typography.Title>
            {paragraphs?.map((paragraph, key) => (
                <Typography.Paragraph
                    className={clsx(styles.paragraph, paragraphClassName)}
                    key={key}
                >
                    {paragraph}
                </Typography.Paragraph>
            ))}
        </Col>
    );
};

Info.Image = InfoImage;
Info.Text = InfoText;

export { Info };
