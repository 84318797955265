import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Typography } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { Button, Space } from '@aml/shared';
import { Layout } from '../Layout';
import { ReactComponent as Logo } from '../../assets/images/ufinance_logo_light.svg';
import styles from './Footer.module.less';

interface FooterProps {
    logo?: React.ReactNode;
}
interface PublicFile {
    id: number;
    fileId: string;
    descriptions: { [key: string]: string };
    category: string;
    categoryOrder: number;
    created: Date;
    statusId: number;
    description: string;
}

const Footer = ({ logo = <Logo height={40} width={150} /> }: FooterProps) => {
    const [t, i18next] = useTranslation();
    const [footerFiles, setFooterFiles] = useState<PublicFile[]>([]);
    useEffect(() => {
        axios
            .get('public/public-files/category/FOOTER')
            .then((response: AxiosResponse) => setFooterFiles(response.data))
            .catch((error) => console.error(error.message));
    }, []);
    return (
        <Layout.Footer className={styles.footer}>
            <Layout.Container>
                <Row gutter={16}>
                    <Col span={24} lg={{ span: 14 }}>
                        <Space>
                            {logo}
                            <Divider
                                className={styles.divider}
                                type="vertical"
                            />
                            <Button type="text-light" className={styles.button}>
                                UFin
                            </Button>
                            <Button type="text-light" className={styles.button}>
                                UCompliance
                            </Button>
                        </Space>
                        <div className={styles.paragraph}>
                            <Typography.Paragraph className={styles.text}>
                                {t('footer_text_1', {
                                    defaultValue:
                                        'Представляет исключительное решение для B2B сектора.',
                                })}
                            </Typography.Paragraph>
                            <Typography.Paragraph className={styles.text}>
                                {t('footer_text_2', {
                                    defaultValue:
                                        'IT решение компании минимизирует расходы и увеличит доходы вашего бизнеса, отвечая требованиям современного кредитного сервиса и меняя привычные способы ведения бизнеса',
                                })}
                            </Typography.Paragraph>
                        </div>
                    </Col>
                    <Col span={24} lg={{ span: 10 }}>
                        <Typography.Paragraph className={styles.text}>
                            {t('footer_info_title', {
                                defaultValue: 'Общая информация',
                            })}
                        </Typography.Paragraph>
                        <ul className={styles.list}>
                            {footerFiles && footerFiles.length
                                ? footerFiles.map(
                                      (file: PublicFile, index: number) => (
                                          <li key={index}>
                                              <a
                                                  href={`${window.location.href}api/v1/public/files/${file.fileId}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                              >
                                                  {
                                                      file.descriptions[
                                                          i18next.language
                                                      ]
                                                  }
                                              </a>
                                          </li>
                                      )
                                  )
                                : null}
                        </ul>
                    </Col>
                    <Col span={24}>
                        <Typography.Paragraph className={styles.text}>
                            ©2020 UFinance
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Layout.Container>
        </Layout.Footer>
    );
};

export { Footer };
