import { Trans, useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { Button, Card } from '@aml/shared';
import clsx from 'clsx';
import { ReactComponent as BgElement } from '../../../assets/images/landing_bg_element.svg';
import { ReactComponent as BgDots } from '../../../assets/images/landing_bg_dots.svg';
import { useScrollTo, useVisibility } from '../helpers';
import styles from './Stats.module.less';

interface StatsProps {
    isMobile?: boolean;
}

const Stats = ({ isMobile }: StatsProps) => {
    const [containerRef, containerClassName] = useVisibility({
        minTopValue: 200,
    });
    const [textsRef, textsClassName] = useVisibility<HTMLUListElement>({
        hide: true,
    });
    const [t] = useTranslation();
    const scrollTo = useScrollTo();
    const onClick = () => {
        scrollTo('partner');
    };
    return (
        <Card
            className={clsx(styles.card, containerClassName)}
            bordered={false}
        >
            <BgDots className={styles.bgDots} />
            <BgElement className={styles.bgElement} />
            <Row ref={containerRef} align="middle" gutter={32}>
                <Col className={styles.texts} span={24} lg={{ span: 12 }}>
                    <Trans
                        i18nKey="landing.stats_title"
                        defaultValue="<1>Более</1> <2>10 000</2>"
                        components={{
                            1: (
                                <Typography.Title
                                    className={styles.text}
                                    style={{ fontSize: 40 }}
                                />
                            ),
                            2: (
                                <Typography.Title
                                    className={styles.text}
                                    style={{ fontSize: 120 }}
                                />
                            ),
                        }}
                    />
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                    <ul
                        ref={textsRef}
                        className={clsx(styles.list, textsClassName)}
                    >
                        <li>
                            {t('landing.stats_bullet_1', {
                                defaultValue:
                                    'Предложений от лучших партнеров на рынке!',
                            })}
                        </li>
                        <li>
                            {t('landing.stats_bullet_2', {
                                defaultValue:
                                    'Уникальных возможностей увеличить кредитный портфель',
                            })}
                        </li>
                        <li>
                            {t('landing.stats_bullet_3', {
                                defaultValue: 'Запросов на ваши услуги',
                            })}
                        </li>
                    </ul>
                    <Button
                        className={styles.partnerBtn}
                        size="large"
                        onClick={onClick}
                        block={isMobile}
                    >
                        {t('landing.stats_action', {
                            defaultValue: 'Стать партнером',
                        })}
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export { Stats };
